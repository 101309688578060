import SubscriptionModal from '@/baseline/subscription';
import UpgradeIcon from '@/baseline/subscription/upgradeIcon';
import useTierPermission from '@/baseline/subscription/useTierPermission';
import CreditCardBrandImage from '@/components/creditCardBrandImage';
import EnhancedDisplay from '@/components/enhancedDisplay';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import NewCardModal from '@/modals/newCardModal';
import { fetchCards } from '@/pages/settings/cards';
import { useModal } from '@/providers/modal';
import { Client } from '@/types/schema';
import { ListItemText, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';

export type SavedCardType = {
	id: string,
	last4: string,
	first6: string,
	cardType: string,
	expirationDate: string,
	token: string
};
export default function SavedCards() {
	const formik = useFormikContext<Client>();
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const confirmDialog = useConfirmDialog();
	const [ cards, setCards ] = useState( [] );
	const { t } = useTranslation();
	const subscriptionIsValid = useTierPermission( 'SAVED_CARDS' );
	
	useAsyncEffect( async () => {
		try {
			if ( !formik.values.externalId ) return;
			await fetchCards( formik.values.id, setCards );
			
		} catch {
		}
		
	}, [ formik.values.id ] );
	
	return (
		<EnhancedDisplay<SavedCardType>
			pageSectionProps={{
				primary      : t( 'common:saved-card' ),
				secondary    : t( 'common:saved-card-detail' ),
				listItemProps: { sx: { px: 0, pb: .5, mb: { xs: 0, sm: 1 } } },
				actions      : [ formik.values.externalId && {
					name   : t( 'common:add-card' ),
					details: !formik.values.email ? 'Please add an email to save cards on File.' : '',
					endNode: !subscriptionIsValid && <UpgradeIcon permission='SAVED_CARDS'/>,
					onClick: () => {
						if ( !subscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
						return showModal( NewCardModal, { maxWidth: 'sm' }, {
								client  : formik.values,
								setCards: setCards,
							},
						);
					},
				} ],
			}}
			data={cards}
			listProps={{
				dense    : true,
				renderRow: ( card ) => (
					<Fragment>
						<ListItemText>{card.first6}</ListItemText>
						<ListItemText>{card.last4}</ListItemText>
						<Typography>{startCase( toLower( card.cardType ) )}</Typography>
						<Typography>{card?.expirationDate
							? `${card.expirationDate.substring( 0, 2 )}/${card.expirationDate.substring( 2 )}`
							: '-'}
						</Typography>
					</Fragment>
				),
			}}
			tableProps={{
				hover    : false,
				size     : 'small',
				headers  : [ `${t( 'common:card' )}#`,
				             t( 'common:type' ),
				             t( 'common:expiration' ),
				             t( 'common:action' ) ],
				columns  : ( card ) => [
					<Stack key='number' direction='row' alignItems='center' spacing={1}>
						<Typography>
							{card.first6 + ' ****** ' + card.last4}
						</Typography>
						<CreditCardBrandImage key='brand' brand={card.cardType}/>
					</Stack>,
					startCase( toLower( card.cardType ) ),
					`${card?.expirationDate
						? `${card.expirationDate.substring( 0, 2 )}/${card.expirationDate.substring( 2 )}`
						: '-'}`,
					<AsyncLoadingButton
						key={card.id}
						color='error'
						variant='text'
						onClick={async () => {
							const value = await confirmDialog( {
								title  : t( 'common:delete' ),
								message: t( 'common:delete-confirmation' ),
							} );
							if ( !value ) return;
							try {
								await axios.post( `/api/processor/payment/deleteCard`, {
									id      : formik.values.id,
									cardId  : card.id,
									sourceId: card.token,
								} );
							} catch {
							}
							
							enqueueSnackbar( t( 'common:card-remove-success' ), { variant: 'success' } );
							await fetchCards( formik.values.id, setCards );
						}}>
						Remove
					</AsyncLoadingButton>,
				],
				cellProps: [
					{ align: 'left' },
					{ align: 'left' },
					{ align: 'left' },
					{ align: 'right' },
				],
			}}
		/>
	);
}
